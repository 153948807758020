import React, { useState }  from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    ActivityIndicator,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';

import Modal from 'modal-enhanced-react-native-web';

import BouncyCheckbox from "react-native-bouncy-checkbox";


import * as firebase from 'firebase';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'


function TermsConditions({ toggleGuestLogInModal }) {

  const navigation = useNavigation();

  const onPress = () => {
    toggleGuestLogInModal(),
    navigation.navigate("Terms")
  }

  return (
    <TouchableOpacity
      onPress={onPress}
    >
      <Text style={{ color: colors.textSecondary, fontFamily: fonts.regular }}> Terms and Conditions.</Text>
    </TouchableOpacity>
  )
}

function SignInGuest({ state, setError, toggleGuestLogInModal }) {

  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const addGuestLogin = (firstName, secondName, phone, email) => {
    return {
      type: "ADD_GUEST_TO_CART",
      payload: {
        firstName: firstName,
        secondName: secondName,
        phone: phone,
        email: email
      },
    }
  }
  const handleRegister = () => {

    setLoading(true)

    const emailReg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const phoneReg = new RegExp(/^((\+44)|(0)) ?\d{4} ?\d{6}$/)

    if (state.email == "" ||
        state.firstName == "" ||
        state.secondName == "" ||
        state.phone == "") {
        setError("Please fill in the required fields.")
        setLoading(false)
    } else {

      if (emailReg.test(state.email) === false) {
        setError("Please enter a valid email address")
        setLoading(false)
      } else if (phoneReg.test(state.phone) === false) {
        setError("Please enter a valid phone number")
        setLoading(false)
      }  else if (state.termsIsChecked === false) {
        setError("You must agree to the Terms & Conditions")
        setLoading(false)
      } else {
        dispatch(addGuestLogin(state.firstName, state.secondName, state.phone, state.email))
        toggleGuestLogInModal()
        setLoading(false)
      }
    }
  }

  return (
    <TouchableOpacity style={styles.button} onPress={() => handleRegister()}>
      {isLoading ?
      <ActivityIndicator size="small" color={colors.text} />
      : <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Log In As Guest</Text> }
    </TouchableOpacity>
  )
}

class GuestLogInModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      secondName: "",
      phone: "",
      errorMessage: null,
      formLoading: false,
      termsIsChecked: false,
    }
  }


  resetError = () => {
    return this.setState({errorMessage: null})
  }

  signInAccount = () => {
    return (
      this.props.toggleLoginModal(),
      this.props.toggleGuestLogInModal()
    )
  }

  setTermsChecked = () => {

    this.resetError()
    if (this.state.termsIsChecked == true) {
      this.setState({termsIsChecked: false})
    } else {
        this.setState({termsIsChecked: true})
    }
  }

  setError = (error) => {
      this.setState({ errorMessage: error })
  }


  renderGuestLogInModal = (isVisible, toggleGuestLogInModal) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <ScrollView style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>

          <View style={[styles.header, { paddingHorizontal: 30, paddingTop: 30,}]}>
            <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Guest Login</Text>
              <TouchableOpacity
                onPress={() => toggleGuestLogInModal()}
                style={styles.headerClose}>
                <Icon name="x" size={25} color={colors.textSecondary}/>
            </TouchableOpacity>
          </View>

          <View style={{ paddingHorizontal: 30, borderTopColor: colors.border, marginTop: 10}}>

          {this.state.errorMessage ?
            <Text style={styles.errorMessage}><Text style={{ fontFamily: fonts.regular }}>Error</Text> {this.state.errorMessage}</Text>
          : null}

          <Text style={[styles.subHeading, {marginTop: 10}]} adjustsFontSizeToFit allowFontScaling>First Name<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            autoCompleteType="givenName"
            onChangeText={firstName => (this.setState({ firstName }, this.resetError()))}
            value={this.state.password}
            ref={(input) => { this.input1 = input; }}
            onSubmitEditing={() => { this.input2.focus(); }}
            ></TextInput>

            <Text style={styles.subHeading} adjustsFontSizeToFit allowFontScaling>Last Name<Text style={styles.inputRequired}> *</Text></Text>
            <TextInput
              style={styles.input}
              autoCapitalize="words"
              autoCompleteType="familyName"
              onChangeText={secondName => (this.setState({ secondName }, this.resetError()))}
              value={this.state.password}
              ref={(input) => { this.input2 = input; }}
              onSubmitEditing={() => { this.input3.focus(); }}
              ></TextInput>

            <Text style={styles.subHeading} adjustsFontSizeToFit allowFontScaling>Email<Text style={styles.inputRequired}> *</Text></Text>
            <TextInput
              style={styles.input}
              autoCapitalize="none"
              autoCompleteType="email"
              onChangeText={email => (this.setState({ email }, this.resetError()))}
              value={this.state.password}
              ref={(input) => { this.input3 = input; }}
              onSubmitEditing={() => { this.input4.focus(); }}
            ></TextInput>

            <Text style={styles.subHeading} adjustsFontSizeToFit allowFontScaling>Mobile Number<Text style={styles.inputRequired}> *</Text></Text>
            <TextInput
              style={styles.input}
              autoCapitalize="none"
              autoCapitalize="none"
              autoCompleteType="telephoneNumber"
              value={this.state.phone}
              onChangeText={phone => (this.setState({ phone }, this.resetError()))}
              value={this.state.phone}
              ref={(input) => { this.input4 = input; }}
            ></TextInput>


          <View style={styles.checkboxSection}>

                            <BouncyCheckbox
                              size={25}
                              isChecked={this.state.termsIsChecked}
                              fillColor={colors.text}
                              unfillColor="#FFFFFF"
                              disableBuiltInState={true}
                              iconStyle={{ borderColor: colors.text }}
                              onPress={() => this.setTermsChecked()}
                            />
                            <View style={styles.checkboxLabel}>
                              <Text>I agree to the</Text>
                                <TermsConditions
                                  toggleGuestLogInModal={() => this.props.toggleGuestLogInModal()}
                                />
                            </View>
                          </View>

            <SignInGuest
              toggleGuestLogInModal={() => this.props.toggleGuestLogInModal()}
              setError={(error) => this.setError(error)}
              state={this.state}
            />

            <View style={{ borderTopWidth: 1, borderTopColor: colors.border, marginTop: 30}}>

              <Text style={{
                  fontSize: 10,
                  letterSpacing: 1,
                  color: colors.text,
                  textAlign:  "center",
                  paddingHorizontal: 10,
                  marginTop: -7,
                  alignSelf: "center",
                  position: "absolute",
                  textTransform: "uppercase",
                  backgroundColor: colors.white,
                  fontFamily: fonts.regular
              }}>Have an Account?</Text>

              <TouchableOpacity
                style={[styles.buttonBorder, { marginBottom: 30, marginTop: 30, }]}
                onPress={() => this.signInAccount()}
              >
                <Text style={[styles.buttonText, { color: colors.primary }]} adjustsFontSizeToFit allowFontScaling>Log in Here</Text>
              </TouchableOpacity>


            </View>

          </View>

          </ScrollView>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderGuestLogInModal(this.props.isVisible, this.props.toggleGuestLogInModal)}</View>
    )
  }
}

export default GuestLogInModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
  },
  modal: {
    minHeight: 290,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  modalWeb: {
    minHeight: 290,
    backgroundColor:colors.white,
    minWidth: 500,
    maxWidth: 500,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 22,
    fontFamily: fonts.bold
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 15,
    textAlign: "center",
    marginTop: -5,
    color: colors.primary,
    fontFamily: fonts.regular
  },
  subHeading: {
    fontSize: 10,
    letterSpacing: 1,
    color: colors.text,
    textTransform: "uppercase",
    fontFamily: fonts.regular
  },
  text: {
    alignItems: 'center',
    textAlign: "center",
    fontFamily: fonts.regular
  },
  button: {
    height: 58,
    backgroundColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonBorder: {
    height: 58,
    borderWidth: 2,
    borderColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 16,
    textAlign: "center",
    color: colors.secondary,
    fontFamily: fonts.bold
  },
  input: {
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 25,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
  inputRequired: {
    color: colors.red,
    fontFamily: fonts.bold
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 20,
    fontFamily: fonts.regular,
  },
  checkboxSection: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: -5,
    flexDirection: "row",
  },
  checkbox: {
    margin: 8,
  },
});
