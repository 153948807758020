// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyC0b8yJ9QGeg1Kj8e9vgG1MHkKF-Kh1g5I",
  authDomain: "yuze-marina-munch.firebaseapp.com",
  projectId: "yuze-marina-munch",
  storageBucket: "yuze-marina-munch.appspot.com",
  messagingSenderId: "1044051622480",
  appId: "1:1044051622480:web:6fe814736501c484fb6f55",
  measurementId: "G-Q45FK0Z6CY"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
